import React from "react";
import StandardPage from "../sections/standardPage/StandardPage";
import { graphql } from 'gatsby'

const CES_MRT = (data) =>
    <StandardPage data={data} imgPadding={"60%"}/>

export default CES_MRT;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 9}) {
            heading
            subheading
            pageName
            pageId
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
